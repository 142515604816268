import {
  Box,
  VStack,
  Text,
  Flex,
  Center,
  Image,
  Button,
  Link as ChakraLink,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";

import ImageAboutUSHeroBanner from "@/assets/images/webp/AboutUsBG.webp";

import PublicEmail from "@/assets/images/PublicEmail.svg";
import PublicLocation from "@/assets/images/PublicLocation.svg";

import { StaticImage } from "gatsby-plugin-image";

import GraphicText from "@/layout/GraphicText";

import Header from "@/components/head";
import Layout from "@layout/index";

const { useRef, useState, useEffect, useMemo, memo } = React;

export const Head = () => {
  return (
    <Header
      title="About Airgram: best meeting management tool for hybrid team."
      description="Airgram strives to make meetings more enjoyable and productive. From 1:1 meetings to team meetings, we have you covered."
    />
  );
};

export interface IAboutProps {}

export const About: React.FC<IAboutProps> = (props) => {
  const {} = props;
  const ctaBg = useBreakpointValue(
    {
      base: "about-cta-no-img",
      sm: "about-cta-no-img",
      md: "about-cta",
      lg: "about-cta",
      xl: "about-cta",
      "2xl": "about-cta",
    },
    {
      fallback: "md",
    }
  );
  return (
    <Layout
      isNewStyle
      topLayout={
        <Center
          w="full"
          pos="absolute"
          h={{
            base: "648px",
            sm: "670px",
            md: "582px",
            lg: "666px",
            xl: "798px",
            "2xl": "830px",
          }}
          flexDir="column"
          color="white"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center center"
          backgroundImage="url('https://a.storyblok.com/f/167495/3840x1660/ec55ae3f7a/aboutusbg.webp')"
        >
          <Text w="50%" color="white" textAlign="center" as="h1">
            Energize meetings to drive team's action and spark innovation
          </Text>
          <Text w="50%" textAlign="center">
            Your intelligent AI assistant throughout the meeting. Empower
            individuals and teams to have efficient and fruitful meetings.
          </Text>
        </Center>
      }
    >
      <VStack
        p={{
          base: "24px",
          sm: "40px",
          md: "40px",
          lg: "64px",
          xl: "80px",
          "2xl": "80px",
        }}
        gap={{
          base: "24px",
          sm: "40px",
          md: "40px",
          lg: "80px",
          xl: "120px",
          "2xl": "120px",
        }}
        mt={{
          base: "538px",
          sm: "536px",
          md: "420px",
          lg: "470px",
          xl: "560px",
          "2xl": "592px",
        }}
        pos="relative"
        background="white"
        borderRadius="24px"
        boxShadow="0px 4px 32px rgba(0, 0, 0, 0.06)"
      >
        <GraphicText
          placement="right"
          gap={{
            base: "24px",
            sm: "40px",
            md: "40px",
            lg: "40px",
            xl: "80px",
            "2xl": "80px",
          }}
          slot={
            <StaticImage
              loading="lazy"
              alt="Google Meet Agenda"
              src="https://a.storyblok.com/f/167495/680x510/7dbc59241d/aboutusairgram.png"
            />
          }
          title="We Are Airgram"
        >
          <Text color="#1B1E28">
            In late 2020, in hope of bringing distributed teams together by
            changing the way we have meetings, we launched Airgram.
          </Text>
          <Text color="#1B1E28" my="18px">
            In July 2022, we won{" "}
            <ChakraLink
              color="#9F2AEC"
              href="https://www.producthunt.com/products/airgram-for-google-meet-zoom"
            >
              #1 Product of the Day
            </ChakraLink>{" "}
            on Product Hunt. So far we have supported teams and individuals to
            arrange efficient and engaging meetings every single day. And we’re
            still striving to push the boundaries by revamping Airgram.
          </Text>
        </GraphicText>
        <GraphicText
          gap={{
            base: "24px",
            sm: "40px",
            md: "40px",
            lg: "40px",
            xl: "80px",
            "2xl": "80px",
          }}
          slot={
            <StaticImage
              loading="lazy"
              alt="Google Meet Agenda"
              src="https://a.storyblok.com/f/167495/680x510/3d6ff996dc/aboutusourmission.png"
            />
          }
          title="Our Mission"
        >
          <Text color="#1B1E28">
            We aspire to erase the information gap in every team’s daily
            communication and collaboration in the hope of helping teams
            effortlessly capture all key insights and track all action plans.
          </Text>
          <Text color="#1B1E28" my="18px">
            Ensure your team an efficient, thoughtful, and fruitful meeting
            based on a dynamic meeting minute in rich media types - agenda
            timer, video recording, timestamped notes, action items, AI topic
            extraction, and threaded comments. Your meeting will never be the
            same.
          </Text>
        </GraphicText>
      </VStack>
      <Text
        as="h2"
        mb="80px !important"
        mt="160px !important"
        textAlign="center"
      >
        Contact Us
      </Text>
      <Flex
        gap="40px"
        flexDir={{
          base: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
          "2xl": "row",
        }}
      >
        <Box
          borderRadius="12px"
          p={{
            base: "24px",
            sm: "24px",
            md: "24px",
            lg: "40px",
            xl: "40px",
            "2xl": "40px",
          }}
          flex={1}
          border="1px solid #EAEDF0"
        >
          <Flex alignItems="center" gap="12px">
            <Image
              objectFit="contain"
              src={PublicEmail}
              w="24px"
              h="24px"
              loading="lazy"
            />

            <Text as="h5">Email us for any questions</Text>
          </Flex>
          <Text>
            <ChakraLink color="#9F2AEC" href="mailto:support@airgram.io">
              support@airgram.io
            </ChakraLink>
          </Text>
        </Box>
        <Box
          borderRadius="12px"
          p={{
            base: "24px",
            sm: "24px",
            md: "24px",
            lg: "40px",
            xl: "40px",
            "2xl": "40px",
          }}
          flex={1}
          border="1px solid #EAEDF0"
        >
          <Flex alignItems="center" gap="12px">
            <Image src={PublicLocation} w="24px" h="24px" loading="lazy" />
            <Text as="h5">Address</Text>
          </Flex>
          <Text>
            1013 Centre Road, Suite 403S, Wilmington, DE 19805, County of New
            Castle, Delaware, U.S.A.
          </Text>
        </Box>
      </Flex>

      <Center
        my="120px"
        borderRadius="12px"
        className={ctaBg}
        p={{
          base: "40px 24px",
          sm: "64px 40px",
          md: "80px",
          lg: "80px 160px",
          xl: "80px 160px",
          "2xl": "80px 160px",
        }}
      >
        <Center flexDir="column">
          <Text textAlign="center" as="h2">
            Elevate your Zoom, Google Meet & Teams meeting experience
          </Text>

          <ChakraLink
            _hover={{
              textDecor: "none",
            }}
            target="_blank"
            href="https://app.airgram.io/signup"
          >
            <Button mt="32px" p="12px 24px" height="50px" colorScheme="brand">
              Try for free
            </Button>
          </ChakraLink>
        </Center>
      </Center>
    </Layout>
  );
};
export default memo(About);
